
const DayJS = require('dayjs');
export function formatDate(date, format) {
    return DayJS(date).format(format);
}

const imageDomainName = "https://ik.imagekit.io/ionicfirebaseapp/getwidget/";
export const baseURL = "https://gatsbymarket.pages.dev/";
export function getImageURL(imageName, extension, width) {
    if (!width) {
        return `${imageDomainName}${imageName}.${extension}`;
    } else {
        return `${imageDomainName}${imageName}.${extension}?tr=w-${width}`;
    }
}