/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import * as React from 'react';
import PropTypes, { func } from "prop-types"
import { getImageURL } from '../../format-date';
import { Helmet } from 'react-helmet';
function SEO({ title, description, image, url, type, blogTags, blogDetails, product_details }) {

  const baseURL = "https://market.getwidget.dev/";
  url = url.startsWith("/") ? url.slice(1) : url;
  const canonicalBaseURL = "https://market.getwidget.dev/";
  let productImages = [];
  if (product_details) {
    product_details.product_images.forEach(p_image => {
      productImages.push(getImageURL(p_image.localFile.name, p_image.localFile.extension));
    });
  }
  let schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: baseURL + url,
      description: description,
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": baseURL
      },
      "image": {
        "@type": "ImageObject",
        "url": image,
        "width": 1200,
        "height": 600
      }
    },
  ];
  if (blogDetails) {
    const blogSchema = {
      "@context": "https://schema.org",
      "@type": "Article",
      "publisher": {
        "@type": "Organization",
        "name": "Flutter Marketplace | Buy App Template and Themse",
        "url": baseURL + 'blog/',
        "logo": {
          "@type": "ImageObject",
          "url": image
        }
      },
      "author": {
        "@type": "Person",
        "name": blogDetails.author.author_name,
        "image": {
          "@type": "ImageObject",
          "url": getImageURL(blogDetails.author.author_profile_picture.localFile.name, blogDetails.author.author_profile_picture.localFile.extension),
          "width": 2000,
          "height": 1333
        },
        "url": blogDetails.author.author_profile_link,
        "headline": title,
        "url": baseURL + url,
        "datePublished": blogDetails.published_at,
        "dateModified": blogDetails.updated_at,
        "image": {
          "@type": "ImageObject",
          "url": image,
          "width": 800,
          "height": 465
        },
        "description": description,
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": baseURL + 'blog/'
        }
      },
    };
    schemaOrgJSONLD.push(blogSchema);
  }
  if (product_details) {
    let categories = [];
    product_details.categories.forEach(category => {
      categories.push(category.category_name);
    });
    let productSchema = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "category": categories,
      "url": baseURL + url,
      "name": title,
      "description": description,
      image: image,
    }
    if (product_details.aggregateRating) {
      productSchema["aggregateRating"] = {
        "@type":"AggregateRating",
        ratingValue:product_details.aggregateRating
      }
      if(product_details.total_ratings_count){
        productSchema["aggregateRating"].reviewCount = product_details.total_ratings_count;
      }
    }
    schemaOrgJSONLD.push(productSchema);
    if (product_details.faqs && product_details.faqs.length) {
      let snippetBody = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [],
      };
      product_details.faqs.forEach(faq => {
        let entityData = {
          "@type": "Question",
          name: faq.faq_title,
          acceptedAnswer: {
            "@type": "Answer",
            text: faq.faq_description,
          },
        };
        snippetBody.mainEntity.push(entityData);
      });
      schemaOrgJSONLD.push(snippetBody);
    }
  };


  setTimeout(() => {
    if (!document.getElementById("fc-chat-widget")) {
      console.log("FC SCRIPT ADDED");
      const fcScript = document.createElement("script");
      fcScript.id = "fc-chat-widget";
      fcScript.src = "https://wchat.in.freshchat.com/js/widget.js";
      fcScript.onload = (loaded) => {
        window.fcWidget.init({
          token: "9b9264ed-8ec8-4423-9b35-13925417c4b2",
          host: "https://wchat.in.freshchat.com",
          siteId: "market.getwidget.dev"
        });
      }
      document.head.appendChild(fcScript);
    }

  }, 3000);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description}></meta>
        <link rel="canonical" href={canonicalBaseURL + url} />
        <link rel="sitemap" type="application/xml" href="/sitemap/sitemap-0.xml"></link>
        <meta http-equiv="Accept-CH" content="DPR, Viewport-Width"></meta>
        <meta property="og:url" content={baseURL + url} />
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="GetWidget is opensource library" />
        {type === "article" ?
          <meta property="article:published_time" content={blogDetails.published_at} /> : ''}
        {type === "article" ?
          <meta property="article:modified_time" content={blogDetails.updated_at} />
          : ''}
        {type === "article" ?
          <meta property="article:author" content={blogDetails.author.author_profile_link} /> : ''}
        {type === "article" ?
          blogTags.map(tag_name => (
            <meta property="article:tag" key={tag_name} content={tag_name} />
          )) : ''}
        <meta name='twitter:title' content={title}></meta>
        <meta name='twitter:description' content={description}></meta>
        <meta name='twitter:image' content={image}></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:site" content="@getwidgetdev"></meta>
        <meta name="twitter:creator" content="@getwidgetdev"></meta>
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD.length > 1 ? schemaOrgJSONLD : schemaOrgJSONLD[0], null, 2)}
        </script>
        {/* <script type='text/javascript'>
           {`
           function initFreshChat() {
             window.fcWidget.init({
                 token: "ac4bdc0d-925b-495b-86c8-1371a729a8b0",
                 host: "https://wchat.in.freshchat.com"
             });
         }
         function initialize(i, t) { var e; i.getElementById(t) ? initFreshChat() : ((e = i.createElement("script")).id = t, e.async = !0, e.src = "https://wchat.in.freshchat.com/js/widget.js", e.onload = initFreshChat, i.head.appendChild(e)) } function initiateCall() { initialize(document, "Freshdesk Messaging-js-sdk") } window.addEventListener ? window.addEventListener("load", initiateCall, !1) : window.attachEvent("load", initiateCall, !1);
           `}
         </script> */}
      </Helmet>
    </>
  )
}

SEO.defaultProps = {
  title: '',
  description: '',
  image: '',
  url: ''
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
}

export default SEO
